.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h4{
  font-weight: 400;
}

/* .App-header {
  background-color: #ffa500;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
body {
  background-color: black;
  color: white;
}
form {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  margin: auto;
  width: auto; 
}
button {
  padding: 15px; /* Increased padding */
  font-size: 14px; /* Larger font size */
  font-weight: bold;
  background-color: white;
  color: black;
  border-radius: 5px;
}

input {
  padding: 15px; /* Increased padding */
  font-size: 14px; /* Larger font size */
  border-radius: 5px;
  width: auto;
}

#footer {
  font-weight:500;
  font-size:13px; 
  position:absolute; 
  bottom:150px; 
  text-align:center; 
  width:100%; 
  left:0px;
}

a {
  color:white
}

.qrcode {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin: 20px; /* Add some margin around the QR code */
  max-width: 300px; /* Maximum width for the QR code */
  max-height: 300px; /* Maximum height for the QR code */
}

.qrIMG {
  max-width: 200px; /* Ensures the image is responsive and fits its container */
  height: auto; 
}

